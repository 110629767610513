import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>
        Hur jobba med förändring när myndigheter slås ihop? Erfarenhetsutbyte
        Rörlighet i staten&nbsp;
      </h1>
      <span className="ingress"></span>
      <p>
        Den 4 okt bjuder Rörlighet i staten in till ett gemensamt, digitalt
        samtal kring hur vi kan jobba med förändring när myndigheter slås ihop.
        Vi tar vara på erfarenheter och samtalar kring framgångsfaktorer och
        fallgropar.
      </p>
      <p>
        Några av Rörlighet i statens medlemmar har varit med om en
        sammanslagning, andra kommer kanske att slås ihop framöver och några
        myndigheter får nya verksamheter eller blir av med verksamheter. Många
        av våra myndigheter står därmed inför stora förändringar.
      </p>
      <p>
        För att lära av varandra och samtala kring frågan bjuder Rörlighet i
        staten nu in till ett gemensamt, digitalt erfarenhetsutbyte, kring hur
        vi kan jobba med förändring när myndigheter slås ihop.
      </p>

      <p>
        <strong>Agenda:</strong>
        <br />- Välkommen och inledning, Ingela Hiltula, ordförande Rörlighet i
        staten
        <br />
        - Vad behöver myndigheter tänka på vid en större förändring? XX,
        Arbetsgivarverket
        <br />- Erfarenheter från en nyligen genomförd sammanslagning, Peter
        Schierbeck, Mediemyndigheten
        <br />- Erfarenhetsutbyte mellan deltagare med olika teman:
        Kommunikation, Administrativa frågor, HR och kulturfrågor,
        Förändringsledning och IT
        <br />- Panelsamtal mellan några deltagare och föredragshållare
      </p>
      <p>
        Samtalet leds av Christina Frimodig, samordnare för Rörlighet i staten
      </p>
      <p>
        <strong>När: </strong>4 okt kl 9-11
      </p>
      <p>
        <strong>Hur: </strong>
        <br />
        Via Zoom
        <br />
        Några dagar före mötet skickas länken till Zoom-mötet till alla anmälda.
      </p>
      <p>
        <strong>Anmälan:</strong>
        <br />
        <a href="https://sv-se.eu.invajo.com/event/rorlighetistaten/hurjobbamedforandringnarmyndigheterslasihoperfarenhetsutbyterorlighetistaten">
          Anmäl dig här
        </a>
      </p>
      <p>
        <strong>Vad är Rörlighet i statens Popup-samtal?</strong>
        <br />
        Ett digitalt erfarenhetsutbyte mellan myndigheter kring en aktuell
        fråga. Samtalet leds av en facilitator och inleds med en introduktion av
        temat, därefter ett samtal mellan deltagarna. Målgruppen är medarbetare
        oavsett roll inom myndigheter i Rörlighet i staten.
      </p>
    </div>
  );
}
export default Page;
